import React, { Fragment } from "react";
import _, { debounce } from "lodash";
import { Segment, Modal, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import ReactModal from "../../utilities/ReactModal";
import $ from "jquery";
import { amazonCalculator, openLinks } from "./buttonHandlers/buttonHandlers";
import {
  handleFTP,
  calcSelectedDTBs,
  calcAllDTBs,
  calcAutoMargin,
  runScrapers,
  updateONO,
  updateDamageInventory,
} from "./buttonHandlers/analysis";
import FamilyManagement from "./ContextMenu/Menus/FamilyManagement/FamilyManagement";
import { openChildren, openInLink } from "./buttonHandlers/openData";
import { appendUnixDate } from "../_genericButtonHandlers/appendUnixDate";
import { appendValue, clearData } from "./buttonHandlers/appendCol";
import { directiveBuilder } from "./buttonHandlers/directiveBuilder/directiveBuilder";
import { fetchColumnSets } from "../../../redux/actions";
import { addNotification } from "../../../redux/actions/notificationActions";
import { getViewableColumns, masterColumnSet } from "./ptColumns";
import DataTablesEditor from "../DatatablesEditor/DataTablesEditor";
import ColumnSetForm from "../ColumnSet/ColumnSetForm";
import PricetoolSidebar from "./PricetoolSidebar";
import PricetoolLog from "./PricetoolLog";
import BarcodeGenerator from "../../utilities/BarcodeGenerator";
import dvdlocator, { baseURL } from "../../../apis/dvdlocator";
import LoadingIcon from "../LoadingIcon/LoadingIcon";
import LoadingModal from "react-modal";
import ModalSwitch from "./buttonHandlers/modals/ModalSwitch";
import MessageModal from "../../MessageModal";
import ShelvingLabelGenerator from "../../utilities/ShelvingLabelGenerator";
import FilterOptions from "./FilterOptions";
import { createQueryParamsFromOptions } from "./filterQueryHelper";
import ActiveFilters from "./ActiveFilters";
import FloatingLabelButton from "../../buttons/FloatingLabel";
import { renderDate } from "../../formatting/dateFormat";
import ContextMenu from "./ContextMenu/ContextMenu";
import { attachDuplicateCheckbox } from "./buttonHandlers/warehouse/duplicateRow";

import { AdjustInventory } from "./ContextMenu/Menus/InventoryAdjustment/AdjustInventory";
import {
  productEditFields,
  supplyEditFields,
  supplyCreateFields,
} from "./configs/editorFormFields";

class Pricetool extends React.Component {
  constructor(props) {
    super(props);

    this.searchDelay = null;
    this.barCodeType = null;
    this.rowData = [];
    this.datatable = null;

    this.datatableOptions = {
      dom: "Blrtip", // https://datatables.net/reference/option/dom
      processing: true,
      serverSide: true,
      select: true,
      paging: true,
      pageLength: 100,
      lengthChange: true,
      autoWidth: true,
      scrollY: window.innerHeight - 200,
      scrollX: true,
      scrollCollapse: true,

      colReorder: { realtime: false },
      deferRender: true,
      deferLoading: true,

      lengthMenu: [25, 50, 100, 300, 500, 1000],
    };

    this.state = {
      modalName: "",
      selectedRowsArray: [],
      appendStatus: false,
      appendErrors: [],
      appendColumnNames: [],
      appendTableNames: [],
      isLoading: false,
      modalIsActive: false,
      msg: "",
      labelModalIsActive: false,
      currentColSet: null,
      isOpenMessageModal: false,
      searchValue: this.props.match.params.search
        ? this.props.match.params.search
        : "",
      contextMenu: {
        display: false,
        event: null,
        rowsPreselected: false,
        modal: "",
        products: [],
        loading: false,
      },

      displayFilterOptions: false,
      filterOptions: {
        retired: false,
      },
      dtDisplayColumns: getViewableColumns(),
      dtEditorFields: masterColumnSet,
      colSets: [],

      outputLog: [],
      outputLogClearedTimestamp: null,

      render: true,
    };

    this.dataTablesRef = React.createRef();
  }

  resetColumns(dt) {
    const url = window.location.href;
    // Special case- if viewing a collection, must keep the product family search filter
    const keepFamilySearch = url.includes("/family/");

    const searchInputs = document.querySelectorAll(".header_search_input_dt");
    const nullFilters = document.querySelectorAll(".header_checkbox_input_dt");

    searchInputs.forEach((element) => (element.value = ""));
    nullFilters.forEach((element) => (element.checked = false));

    // Setting all null filters to false (Control)
    this.setState({
      dtDisplayColumns: this.state.dtDisplayColumns.map((col) => ({
        ...col,
        filterNulls: false,
      })),
    });

    if (!keepFamilySearch) return dt.columns().search("").draw();

    const familySearch = this.props.match.params.family;
    dt.columns()
      .search("")
      .column("product_families_view.families:name")
      .search(familySearch)
      .draw();
  }

  clearOutputLog = () => {
    this.setState((prev) => ({
      outputLogClearedTimestamp: renderDate(Math.floor(Date.now() / 1000)),
      outputLog: prev.outputLog.filter(
        (_, i) => i === prev.outputLog.length - 1
      ),
    }));
  };

  componentDidUpdate(prevProps, prevState) {
    // updating datatable as ref object changes
    this.datatable = this.dataTablesRef.current.dataTable;
  }

  async componentDidMount() {
    let shouldDraw = true;

    while (this.dataTablesRef === null) {
      await new Promise((resolve) => setTimeout(resolve, 100)); // waiting for datatables
    }

    this.datatable = this.dataTablesRef.current.dataTable;

    if (this.state.searchValue) {
      this.datatable.search(this.state.searchValue); // run initial search if given
    }

    if (this.props.match.params.family) {
      shouldDraw = false;
      const familySearch = this.props.match.params.family;
      const familyColumn = "product_families_view.families";
      document.getElementById(`header_search_${familyColumn}`).value =
        familySearch;
      this.datatable.column(`${familyColumn}:name`).search(familySearch);
    }

    while (this.props.auth.userId === undefined) {
      await new Promise((resolve) => setTimeout(resolve, 100)); // waiting for auth
    }

    if (this.props.auth.userId) {
      this.props.fetchColumnSets(this.props.auth.userId);
    }

    while (this.props.columnSets[this.props.auth.userId] === undefined) {
      await new Promise((resolve) => setTimeout(resolve, 100)); // waiting for columnsets
    }

    this.setState({ colSets: this.props.columnSets[this.props.auth.userId] });

    // load initial colset if one given
    if (this.props.match.params.colset) {
      shouldDraw = false;
      this.setColumnSet(this.props.match.params.colset);
    }

    if (shouldDraw) this.datatable.draw();
  }

  handleAppendMessage = (rows, status, errorFields) => {
    this.setState({
      modalName: "AppendMessage",
      selectedRowsArray: rows.toArray(),
      appendStatus: status,
      appendErrors: errorFields,
    });
  };

  handleNullFilter = (columnName, isFiltered, dt) => {
    this.setState(
      {
        dtDisplayColumns: this.state.dtDisplayColumns.map((col) =>
          col.data === columnName ? { ...col, filterNulls: isFiltered } : col
        ),
      },
      () => {
        dt.draw();
      }
    );
  };

  showAppendForm = (tableNames, columnNames) => {
    this.setState({
      modalName: "AppendForm",
      appendColumnNames: columnNames,
      appendTableNames: tableNames,
    });
  };

  submitAppendForm = (appendDateFlag, formData) => {
    let editor;
    if (!appendDateFlag) {
      editor = appendValue(
        this.datatable,
        this.dataTablesRef.current.editor,
        this.state.appendTableNames,
        this.state.appendColumnNames.map((col) => col.name),
        formData
      );
    } else {
      editor = appendUnixDate(
        this.datatable,
        this.dataTablesRef.current.editor,
        this.state.appendTableNames,
        this.state.appendColumnNames.map((col) => col.name)
      );
    }

    const selectedRowsApi = this.datatable.rows({ selected: true }).data();

    editor &&
      editor.submit(
        () => {
          this.handleAppendMessage(selectedRowsApi, true);
        },
        (errObj) => {
          this.handleAppendMessage(selectedRowsApi, false, errObj.fieldErrors);
        }
      );
  };

  // search field onChange event handler
  onChangeSearch = (e) => {
    const { value } = e.target;
    const searchValue = value;

    this.setState({ searchValue });

    // waits for user to stop typing for 2 seconds to run search
    clearTimeout(this.searchDelay);
    this.searchDelay = setTimeout(() => {
      this.datatable.draw();
    }, 2000);
    this.displayLoading();
  };

  handleSubmit = (e) => {
    // if user has pressed the enter key, trigger search immediately
    if (e.key === "Enter") {
      clearTimeout(this.searchDelay);
      this.displayLoading();
      this.datatable.draw();
    }
  };

  // changes the state of the loading icon on the application after 1 second of inactivity from the user
  displayLoading = debounce(() => {
    this.setState({ isLoading: true });
  }, 1000);

  customButtons = () => {
    return [
      {
        text: "<span title='Refresh Table'>Refresh</span>",
        action: (e, dt, node, config) => {
          dt.ajax.reload();
        },
      },
      {
        text: "<span title='Clears the filters, searches, and sorts of your column only'>Reset Columns</span>",
        action: (e, dt, node, config) => {
          this.resetColumns(dt);
        },
      },
      {
        text: "<select style='font-size: 10px;width:30px;height:100%;' id='housedir'><option>H</option> </select><select style='font-size: 10px;width:50px;height:100%;' class='selectstore' ><option>Alliance</option><option>Amazon</option><option>AmazonCa</option><option>AmazonFBA</option><option>AmazonUK</option><option>Barnes&Noble</option><option>BestBuy</option><option>Blowit</option><option>Blowit Ebay</option><option>BookDepository</option><option>BullMoose</option><option>CCVideo</option><option>DealsAreUs</option><option>DeepDiscount</option><option>Discount Entertainment</option><option>Ebay</option><option>Gruv</option><option>ImportCDs</option><option>InetVideo</option><option>Mila</option><option>PBS</option><option>Rarewaves</option><option>Rightstuff</option><option>Scholastic</option><option>Target</option><option>UNV Amazon</option><option>UNV Ebay</option><option>UNV Half</option><option>VEI</option><option>Walmart</option><option>Wildcard1</option><option>Wildcard2</option><option>Wildcard3</option><option>Wildcard4</option><option>WowHD</option><option>UA11</option></select><input type='text' id='initials' placeholder='Init.' style='min-width:1px;font-size:9px;' size='1'>",
        action: function (e, dt, node, config) {},
      },
      {
        text: "<span title='Create Directive'>Drctv</span>",
        action: (e, dt, node, config) => {
          const ret = directiveBuilder(dt, this.dataTablesRef.current.editor);
          if (ret) {
            const { outputLog } = this.state;

            this.setState({ outputLog: [...outputLog, ret] });
          }
        },
      },

      {
        text: "<select title='Select the input mode for the Amazon Price Calculator. \n\nCogs-Only: Only asks for the Cost of the Good.' style='font-size: 10px;width:35px;height:100%;' id='selectamzcalcfillmode'><option>Cogs-Only</option></select> <select style='font-size: 10px;width:35px;height:100%;' id='selectamzcalc2'><option>None</option><option>Single</option><option>Multi</option></select>",
        action: function (e, dt, node, config) {},
      },
      {
        extend: "selectedSingle",
        text: "<span title='Amazon Price Calculator'>Amz Price Calc</span>",
        action: async (e, dt, node, config) => {
          const fees = await amazonCalculator(dt);

          if (fees) {
            const { outputLog } = this.state;

            this.setState({ outputLog: [...outputLog, fees] });
          }
        },
      },
      {
        extend: "collection",
        text: "<span title=''>Supplier</span>",
        autoClose: true,
        buttons: [
          {
            text: `<span><img class='small-icon' title = "Open Alliance"  src=${require("../../../assets/icons/alliance.png")} alt="Alliance" /></span>`,
            action: () => {
              openLinks(this.dataTablesRef.current.dataTable, "alliance");
            },
          },
          {
            text: "Selected DTBs",
            action: (e, dt, node, config) => {
              calcSelectedDTBs(dt);
            },
          },
          {
            text: "All DTBs",
            action: (e, dt, node, config) => {
              const accepted = window.confirm(
                `Are you sure you want to run the "All DTBs" task? It will reset all DTBs before running.`
              );

              if (!accepted) return;
              calcAllDTBs();
            },
          },
          {
            text: "All FTP",
            action: (e, dt, node, config) => {
              handleFTP({ alliance: true, dealsareus: true });
            },
          },
          {
            text: "Alliance FTP",
            action: (e, dt, node, config) => {
              handleFTP({ alliance: true });
            },
          },
          {
            text: "DealsAreUs FTP",
            action: (e, dt, node, config) => {
              handleFTP({ dealsareus: true });
            },
          },

          {
            text: "<span title='Upload CSV pricing/inventory data from limited set of suppliers'>Upload Supplier Data</span>",
            action: (e, dt, node, config) => {
              this.setState({
                modalName: "UploadSupplierData",
              });
            },
          },
          {
            text: "<span title='Clear out prices for selected suppliers'>Clear Supplier Data</span>",
            action: (e, dt, node, config) => {
              this.setState({
                modalName: "ClearSupplierData",
              });
            },
          },
          {
            text: "<span title='Run on demand scrapers on selected rows'>Selected Scrapers</span>",
            action: (e, dt, node, config) => {
              runScrapers(dt);
            },
          },
          {
            text: "Update ONO",
            action: (e, dt, node, config) => {
              updateONO();
            },
          },
        ],
      },
      {
        extend: "collection",
        text: "<span title=''>Work Orders </span>",
        autoClose: true,
        buttons: [
          {
            text: "<span title='Used by an Analyst to mark up a product with a work order for warehouse personnel. Repair means we are going to fix the product in house, Return means it will be going back to the supplier.'>Damage Work Order</span>",
            action: (e, dt, node, config) => {
              const selectedRowsApi = dt.rows({ selected: true }).data();
              this.setState({
                modalName: "DamageWorkOrder",
                selectedRowsArray: selectedRowsApi.toArray(),
              });
            },
          },
          {
            text: "<span title='Used when making manual edits to the Damage Datatable - updates the DMG_INV column to reflect these edits.'>Manual Update DMG Inv</span>",
            action: (e, dt, node, config) => {
              updateDamageInventory();
            },
          },
          {
            text: "<span title=''>Shelf Reassignment</span>",
            action: (e, dt, node, config) => {
              alert("Not implemented yet");
            },
          },
        ],
      },
      {
        extend: "collection",
        text: "<span title='Analyze Item'>Analysis</span>",
        autoClose: true,
        buttons: [
          {
            text: "<span title='Copy selected UPCs'>UPCs</span>",
            action: (e, dt, node, config) => {
              const data = dt.rows({ selected: true }).data();
              let upcs = [];

              data.each((row) => {
                upcs.push(row["product"]["upc"]);
              });

              if (upcs.length) {
                this.setState({
                  msg: upcs.join(","),
                  isOpenMessageModal: true,
                });
              } else {
                alert("Please select row(s)");
              }
            },
          },
          {
            text: "<span title='Sum up the values of a specific column for selected rows.'>Sum Column</span>",
            action: (e, dt, node, config) => {
              const selectedRowsApi = dt.rows({ selected: true }).data();
              this.setState({
                modalName: "SumColumn",
                selectedRowsArray: selectedRowsApi.toArray(),
              });
            },
          },
          {
            text: "<span title='Push the current Next Ship Qty value into the Amz Ship Qty column.'>NSQ to ASQ</span>",
            action: (e, dt, node, config) => {
              const rows = dt.rows({ selected: true });

              const editor = this.dataTablesRef.current.editor;
              editor.edit(rows.indexes(), false);

              rows.every(function () {
                const data = this.data();
                const nextShipQty = data.product.next_amz_ship_qty;
                editor
                  .field("product.amz_ship_qty")
                  .multiSet(data.DT_RowId, nextShipQty);
                return null;
              });

              editor.submit(
                () => {
                  this.props.addNotification({
                    text: `Successfully pushed Next Ship Qty to Amz Ship Qty!`,
                    icon: <Icon color="green" name="checkmark box" />,
                  });
                },
                (errObj) => {
                  console.log(errObj);
                  this.props.addNotification({
                    text: `Error pushing Next Ship Qty to Amz Ship Qty.`,
                    icon: <Icon color="red" name="warning circle" />,
                  });
                }
              );
            },
          },
          {
            text: "<span title='Push the current Yom Ship Rec value into the Next Ship Qty column.'>YSR to NSQ</span>",
            action: (e, dt, node, config) => {
              const rows = dt.rows({ selected: true });

              const editor = this.dataTablesRef.current.editor;
              editor.edit(rows.indexes(), false);

              rows.every(function () {
                const data = this.data();
                const yomShipRec = data.product.yom_ship_rec;
                editor
                  .field("product.next_amz_ship_qty")
                  .multiSet(data.DT_RowId, yomShipRec);
                return null;
              });

              editor.submit(
                () => {
                  this.props.addNotification({
                    text: `Successfully pushed Yom Ship Rec to Next Ship Qty!`,
                    icon: <Icon color="green" name="checkmark box" />,
                  });
                },
                (errObj) => {
                  console.log(errObj);
                  this.props.addNotification({
                    text: `Error pushing Yom Ship Rec to Next Ship Qty.`,
                    icon: <Icon color="red" name="warning circle" />,
                  });
                }
              );
            },
          },
          {
            text: "Auto Margin Script",
            action: (e, dt, node, config) => {
              calcAutoMargin();
            },
          },
          {
            text: "Amazon Orders Script",
            action: (e, dt, node, config) => {
              alert("Not implemented yet");
            },
          },
          {
            text: "ATS Script",
            action: (e, dt, node, config) => {
              dvdlocator
                .post("/invoke-ats")
                .then((res) => {
                  if (res.data.includes("Task already running")) {
                    this.props.addNotification({
                      text: `ATS task already running.`,
                      icon: <Icon color="yellow" name="checkmark box" />,
                    });
                  } else
                    this.props.addNotification({
                      text: `Successfully started ATS task!`,
                      icon: <Icon color="green" name="checkmark box" />,
                    });
                })
                .catch((err) =>
                  this.props.addNotification({
                    text: `Error starting ATS script.`,
                    icon: <Icon color="red" name="warning circle" />,
                  })
                );
            },
          },
          {
            text: "MAVCG Script",
            action: (e, dt, node, config) => {
              dvdlocator
                .post("/invoke-mavcg")
                .then((res) => {
                  if (res.data.includes("Task already running")) {
                    this.props.addNotification({
                      text: `MAVCG task already running.`,
                      icon: <Icon color="green" name="checkmark box" />,
                    });
                  } else
                    this.props.addNotification({
                      text: `Successfully started MAVCG task!`,
                      icon: <Icon color="green" name="checkmark box" />,
                    });
                })
                .catch((err) =>
                  this.props.addNotification({
                    text: `Error starting MAVCG script.`,
                    icon: <Icon color="red" name="warning circle" />,
                  })
                );
            },
          },
          {
            extend: "selected",
            text: "Retire/Unretire",
            action: (e, dt, node, config) => {
              const products = dt.rows({ selected: true });

              const productIds = products
                .data()
                .map((item) => item.product.yom_sku)
                .toArray();

              let retiring = true;

              products.every(function (i, tableLoop, rowLoop) {
                const data = this.data();
                if (data.product.retired) retiring = false;
                return null;
              });

              dvdlocator
                .post("/products/retire", { productIds, retired: retiring })
                .then(() => {
                  this.props.addNotification({
                    text: `Successfully ${
                      retiring ? "retired" : "unretired"
                    } product(s)!`,
                    icon: <Icon color="green" name="checkmark box" />,
                  });
                  dt.ajax.reload();
                })
                .catch((err) => {
                  console.log(err);
                  this.props.addNotification({
                    text: `There was an error ${
                      retiring ? "retiring" : "unretiring"
                    } the product(s)!`,
                    icon: <Icon color="red" name="warning circle" />,
                  });
                });
            },
          },
          {
            text: "Refresh Analytics Columns",
            action: (e, dt, node, config) => {
              dvdlocator
                .post("/refresh-analytics-db-columns")
                .then((res) => {
                  this.props.addNotification({
                    text: `Successfully started task! Please wait a moment for update.`,
                    icon: <Icon color="green" name="checkmark box" />,
                  });
                })
                .catch((err) =>
                  this.props.addNotification({
                    text: `Error starting task.`,
                    icon: <Icon color="red" name="warning circle" />,
                  })
                );
            },
          },
          {
            text: "Clear Sidebar",
            action: (e, dt, node, config) => {
              this.setState({ outputLog: [] });
            },
          },
        ],
        fade: 100,
      },
      {
        extend: "collection",
        text: "<span title='Ware House Actions'>Warehouse</span>",
        autoClose: true,
        buttons: [
          {
            text: "Inventory Value",
            action: function (e, dt, node, config) {
              alert("Not implemented yet.");
            },
          },
          {
            extend: "selected",
            text: "<span title='Amazon Barcode'>Amazon Barcode</span>",
            action: (e, dt, node, config) => {
              const data = dt.rows({ selected: true }).data();

              // reset rowData first
              this.rowData = [];

              if (data.length === 1) {
                const count = prompt(
                  "How many copies of this barcode would you like?",
                  1
                );

                // add count copies of data[0] to this.rowData
                for (let i = 0; i < count; i++) {
                  this.rowData.push(data[0]);
                }
              } else {
                // otherwise add amz_ship_qty amount of each row
                for (let i = 0; i < data.length; i++) {
                  const { amz_ship_qty } = data[i].product;

                  if (amz_ship_qty > 0) {
                    for (let j = 0; j < amz_ship_qty; j++) {
                      this.rowData.push(data[i]);
                    }
                    // if amz_ship_qty empty or 0, just add one copy
                  } else {
                    this.rowData.push(data[i]);
                  }
                }
              }

              this.barCodeType = "amazon";
              this.setState({ modalIsActive: true });
            },
          },
          {
            extend: "selected",
            text: "<span title='Bar Code'>UPC Barcode</span>",
            action: (e, dt, node, config) => {
              const data = dt.rows({ selected: true }).data();

              // reset rowData first
              this.rowData = [];

              if (data.length === 1) {
                const count = prompt(
                  "How many copies of this barcode would you like?",
                  1
                );
                // add count copies of data[0] to this.rowData
                for (let i = 0; i < count; i++) {
                  this.rowData.push(data[0]);
                }
              } else {
                this.rowData = data;
              }

              this.barCodeType = "upc";
              this.setState({ modalIsActive: true });
            },
          },
          {
            extend: "selected",
            text: "<span title='Generate warehouse shelving slot label for selected row(s)'>Generate Slot Label</span>",
            action: (e, dt, node, config) => {
              const data = dt.rows({ selected: true }).data();
              // reset rowData first
              this.rowData = [];

              if (data.length === 1) {
                const count = prompt(
                  "How many copies of this label would you like?",
                  1
                );
                // add count copies of data[0] to this.rowData
                for (let i = 0; i < count; i++) {
                  this.rowData.push(data[0]);
                }
              } else {
                this.rowData = data;
              }
              this.setState({ labelModalIsActive: true });
            },
          },
          {
            text: "Generate Shelving List",
            action: (e, dt, node, config) => {
              this.setState({ modalName: "ShelvingList" });
            },
          },
          {
            extend: "selectedSingle",
            text: "<span style='pointer-events: auto !important;' title='Duplicate the following fields into a new row: ASIN, Title, Ship Wt, Card#, Member of, WWO, WN, Shelf, Genre'>Duplicate Row</span>",
            action: async (e, dt, node, config) => {
              this.openDuplicateForm();
            },
          },
        ],
        fade: 100,
      },
      {
        extend: "collection",
        text: "<span title='Jons Precious Menu'>Curator</span>",
        autoClose: true,
        buttons: [
          {
            text: "<span title='Append / Remove from Listory'>Append Listory</span>",
            action: (e, dt, node, config) => {
              this.showAppendForm(
                ["product"],
                [{ name: "listory", type: "string" }]
              );
            },
          },
          {
            text: "<span title='Clear Sort Column '>Clear \"Sort\" Column</span>",
            action: (e, dt, node, config) => {
              if (
                window.confirm(
                  "Are you sure you would like to clear the data in the Sort column?"
                )
              ) {
                clearData(
                  dt,
                  this.dataTablesRef.current.editor,
                  ["product"],
                  ["sort"]
                );

                clearData(
                  dt,
                  this.dataTablesRef.current.editor,
                  ["supply"],
                  ["sort"]
                );
              }
            },
          },
        ],
      },
      {
        extend: "collection",
        text: "<span title='Search for Items'>Open</span>",
        autoClose: true,
        buttons: [
          {
            text: "<span title='Open Families'>Product Families</span>",
            action: (e, dt, node, config) => {
              openChildren(dt, this.state.currentColSet);
            },
          },
          {
            text: "<span title='Open ItemIDs in Repricer Express'>Repricer</span>",
            action: (e, dt, node, config) => {
              openInLink(
                dt,
                "https://dashboard-5.repricer.com/repricer?fai_fca_id%5B%5D=52849&fsf_query=",
                "supply",
                "upc",
                ""
              );
            },
          },
          {
            text: "<span title='Open UPC in Gmail' >Gmail</span>",
            action: (e, dt, node, config) => {
              openInLink(
                dt,
                "https://mail.google.com/mail/u/0/#search/",
                "supply",
                "upc",
                ""
              );
            },
          },
          {
            text: "<span title='Open UPC in Amazon Order History' >Order History</span>",
            action: (e, dt, node, config) => {
              openInLink(
                dt,
                "https://sellercentral.amazon.com/orders-v3/search?page=1&date-range=last-7&q=",
                "supply",
                "upc",
                "&qt=sku"
              );
            },
          },
          {
            text: "<span title='Open UPC in Amazon Orders table' >Orders</span>",
            action: (e, dt, node, config) => {
              openInLink(dt, "/datatables/amazon-orders/", "supply", "upc", "");
            },
          },
          {
            text: "<span title='Open ASIN in Buy Box' >Buy Box</span>",
            action: (e, dt, node, config) => {
              openInLink(
                dt,
                "https://www.amazon.com/dp/",
                "product",
                "asin",
                ""
              );
            },
          },
          {
            text: "<span title='Open UPC in Re-Stock / Price' >Re-Stock</span>",
            action: (e, dt, node, config) => {
              openInLink(
                dt,
                "https://sellercentral.amazon.com/inventory/view/PRICING?tbla_myitable=sort:%7B%22sortOrder%22%3A%22DESCENDING%22%7D;search:",
                "product",
                "fnsku",
                ";pagination:1;"
              );
            },
          },
          {
            text: "<span title='Open inventory management for ASIN in Seller Central' >Manage Inventory</span>",
            action: (e, dt, node, config) => {
              dt.rows({ selected: true }).every(function () {
                const rowData = this.data();
                const asin = rowData.product.asin;

                if (asin && asin.trim()) {
                  const trimmedAsin = asin.trim();
                  window.open(
                    `https://sellercentral.amazon.com/myinventory/inventory?fulfilledBy=all&page=1&pageSize=10&searchField=all&searchTerm=${trimmedAsin}&sort=sales_desc&status=all`
                  );
                }

                return null;
              });
            },
          },
          {
            text: "<span title='Open UPC in Amazon Pricing table' >Amz Pricing</span>",
            action: (e, dt, node, config) => {
              openInLink(
                dt,
                "/datatables/amazon-pricing/",
                "supply",
                "upc",
                ""
              );
            },
          },
          {
            text: "<span title='Open UPC in House Ordered table' >House</span>",
            action: (e, dt, node, config) => {
              openInLink(dt, "/datatables/house/", "supply", "upc", "");
            },
          },
          {
            text: "<span title='Open UPC in House Received table' >House Received</span>",
            action: (e, dt, node, config) => {
              openInLink(
                dt,
                "/datatables/house-received/",
                "supply",
                "upc",
                ""
              );
            },
          },
          {
            text: "<span title='Open UPC in Damaged table' >Damages</span>",
            action: (e, dt, node, config) => {
              openInLink(dt, "/datatables/damage/", "supply", "upc", "");
            },
          },
        ],
        fade: 100,
      },
      {
        extend: "collection",
        text: "<span title='Append to Table'>Append</span>",
        buttons: [
          {
            text: "<span title='Append / Remove from Part_Number'>Partnum</span>",
            action: (e, dt, node, config) => {
              this.showAppendForm(
                ["product"],
                [{ name: "part_number", type: "string" }]
              );
            },
          },
          {
            text: "<span title='Append / Remove from Listed'>Listed</span>",
            action: (e, dt, node, config) => {
              this.showAppendForm(
                ["supply"],
                [{ name: "listed", type: "string" }]
              );
            },
          },

          {
            text: "<span title='Append / Remove from House'>House</span>",
            action: (e, dt, node, config) => {
              this.showAppendForm(
                ["supply"],
                [{ name: "house", type: "string" }]
              );
            },
          },
          {
            text: "<span title='Append / Remove from Amazon_Queue'>Amazon Queue</span>",
            action: (e, dt, node, config) => {
              this.showAppendForm(
                ["product"],
                [{ name: "amz_queue", type: "string" }]
              );
            },
          },
          {
            text: "<span title='Append / Remove from Orders'>Orders</span>",
            action: (e, dt, node, config) => {
              this.showAppendForm(
                ["product"],
                [{ name: "orders", type: "string" }]
              );
            },
          },
          {
            text: "<span title='Append / Remove from Warehouse Notes'>Wn</span>",
            action: (e, dt, node, config) => {
              this.showAppendForm(
                ["product"],
                [{ name: "wn", type: "string" }]
              );
            },
          },
          {
            text: "<span title='Append / Remove from Pick Status'>Pick Status</span>",
            action: (e, dt, node, config) => {
              this.showAppendForm(
                ["product"],
                [{ name: "pick_status", type: "string" }]
              );
            },
          },
          {
            text: "<span title='Append/remove from Amazon Queue and Next Ship Qty'>Amazon Queue & Next Ship Qty</span>",
            action: (e, dt, node, config) => {
              this.showAppendForm(
                ["product", "product"],
                [
                  { name: "amz_queue", type: "string" },
                  { name: "next_amz_ship_qty", type: "number" },
                ]
              );
            },
          },
          {
            text: "<span title='Append the current day to the Release Date'>Release Date</span>",
            action: (e, dt, node, config) => {
              this.showAppendForm(
                ["product"],
                [{ name: "release_date", type: "string" }]
              );
            },
          },
          {
            text: "<span title='Append the current day to the Add Date'>Add Date</span>",
            action: (e, dt, node, config) => {
              this.showAppendForm(
                ["product"],
                [{ name: "add_date", type: "string" }]
              );
            },
          },
          {
            text: "<span title='Append the current day to the Sub Date'>Sub Date</span>",
            action: (e, dt, node, config) => {
              this.showAppendForm(
                ["product"],
                [{ name: "sub_date", type: "string" }]
              );
            },
          },
        ],
        fade: 100,
      },
    ];
  };

  existingColumnSearches() {
    const columns = this.datatable.columns();

    const searches = [];

    columns.every(function () {
      const data = this.dataSrc();
      const search = this.search();

      if (search !== "") {
        searches.push({
          data,
          search,
        });
      }
      return null;
    });

    return searches;
  }

  setColumnSet = (colSet, skipDraw = false) => {
    const { userId } = this.props.auth;
    const columnNames = this.props.columnSets[userId][colSet];

    const existingSearches = this.existingColumnSearches();

    let viewableColumns = getViewableColumns(columnNames);

    viewableColumns.forEach((column) => {
      const match = existingSearches.find((search) => {
        return search.data === column.data;
      });

      if (match) {
        column.search = {
          value: match.search,
        };
      }
    });

    // forces datatable to re-render with updated columns
    this.setState(
      {
        dtDisplayColumns: viewableColumns,
        currentColSet: colSet,
      },
      () => {
        this.setState({ render: !this.state.render }, () => {
          if (existingSearches.length > 0) return;
          if (skipDraw === true) return;
          this.datatable.draw();
        });
      }
    );
  };

  // handler for opening the barcode modal
  setModalOpen = (val) => {
    this.setState({
      modalIsActive: val,
      labelModalIsActive: val,
      isOpenMessageModal: val,
    });
  };

  // abstracts openLinks function to avoid having to pass a ref to the left sidebar
  openPricetoolLink = (store) => {
    if (this.dataTablesRef.current) {
      openLinks(this.dataTablesRef.current.dataTable, store);
    }
  };

  filterCount() {
    let count = 0;
    Object.values(this.state.filterOptions).forEach((val) =>
      val ? (count += 1) : null
    );
    return count;
  }

  filtersAreActive() {
    return Object.values(this.state.filterOptions).some((value) => {
      if (value) return true;
      else return false;
    });
  }

  closeFormModal = () => {
    this.setState({
      modalName: "",
      selectedRowsArray: [],
    });
  };

  inlineEditHandler = (rowData) => {
    const editor = this.dataTablesRef.current.editor;

    // Checking if row is a sellable product or supply unit
    if (!rowData.supply.upc) {
      // Hiding/showing fields for product only
      masterColumnSet.forEach((col) => {
        if (!col.name.includes("supply.")) {
          editor.field(col.name).show();
        } else {
          editor.field(col.name).hide();
        }
      });
    } else {
      // Hiding/showing fields for supply only
      masterColumnSet.forEach((col) => {
        if (supplyEditFields.includes(col.name)) {
          editor.field(col.name).show();
        } else {
          editor.field(col.name).hide();
        }
      });
    }
  };

  openCreateForm = () => {
    const editor = this.dataTablesRef.current.editor;

    masterColumnSet.forEach((col) => {
      if (supplyCreateFields.includes(col.name)) {
        editor.field(col.name).show();

        if (col.name === "supply.upc") {
          editor.field(col.name).enable();
        }
      } else {
        editor.field(col.name).hide();
      }
    });

    editor.create(1, true, {
      title: "Create New Supply Unit",
      buttons: [
        {
          label: "Create supply unit",
          fn: function () {
            this.submit();
          },
          className: "primary",
        },
      ],
    });
  };

  openDuplicateForm = () => {
    const dt = this.dataTablesRef.current.dataTable;
    const editor = this.dataTablesRef.current.editor;
    const row = dt.row({ selected: true }).data();

    // Checking if row is a sellable product or supply unit
    if (!row.supply.upc) {
      // Hiding/showing fields for product only
      masterColumnSet.forEach((col) => {
        if (productEditFields.includes(col.name)) {
          editor.field(col.name).show();
        } else {
          editor.field(col.name).hide();
        }
      });

      editor
        .edit(dt.row({ selected: true }).nodes(), {
          title: "Duplicate product",
          buttons: [
            {
              label: "Create",
              fn: function () {
                this.submit();
              },
              className: "primary",
            },
          ],
        })
        .mode("create");

      editor.set("product.title", `DUPLICATE - ${row.product.title}`);
    } else {
      // Hiding/showing fields for supply only
      masterColumnSet.forEach((col) => {
        if (supplyEditFields.includes(col.name)) {
          editor.field(col.name).show();

          if (col.name === "supply.upc") {
            editor.field(col.name).enable();
          }
        } else {
          editor.field(col.name).hide();
        }
      });

      editor
        .edit(dt.row({ selected: true }).nodes(), {
          title: "Duplicate supply unit",
          buttons: [
            {
              label: "Create",
              fn: function () {
                this.submit();
              },
              className: "primary",
            },
          ],
        })
        .mode("create");

      editor.set("supply.upc", ``);
      editor.set(
        "supply.supply_title",
        `DUPLICATE - ${row.supply.supply_title}`
      );
    }

    attachDuplicateCheckbox(editor, dt);
  };

  openEditForm = () => {
    const dt = this.dataTablesRef.current.dataTable;
    const editor = this.dataTablesRef.current.editor;
    const rows = dt.rows({ selected: true }).data().toArray();

    // Checking if row is a sellable product or supply unit
    if (rows.some((row) => !row.supply.upc)) {
      // Hiding/showing fields for product only
      masterColumnSet.forEach((col) => {
        if (productEditFields.includes(col.name)) {
          editor.field(col.name).show();
        } else {
          editor.field(col.name).hide();
        }
      });

      editor.edit(dt.row({ selected: true }).nodes(), {
        title: "Edit Product Details",
        buttons: [
          {
            label: "Save Changes",
            fn: function () {
              this.submit();
            },
            className: "primary",
          },
        ],
      });
    } else {
      // Hiding/showing fields for supply only
      masterColumnSet.forEach((col) => {
        if (supplyEditFields.includes(col.name)) {
          editor.field(col.name).show();

          if (col.name === "supply.upc") {
            editor.field(col.name).disable();
          }
        } else {
          editor.field(col.name).hide();
        }
      });

      editor.edit(dt.row({ selected: true }).nodes(), {
        title: "Edit Supply Unit Details",
        buttons: [
          {
            label: "Save Changes",
            fn: function () {
              this.submit();
            },
            className: "primary",
          },
        ],
      });
    }
  };

  handleContextMenu = (e) => {
    if (window.getSelection().toString()) return; // if the user has highlighted text

    e.preventDefault();
    let rowsPreselected = true;

    $("tr").removeClass("contextmenu"); // cleaning other selections

    const dt = this.dataTablesRef.current.dataTable;
    let rows = dt.rows({ selected: true }).data().toArray();

    const clickedRow = $(e.target).closest("tr").first();

    // If no selection, use the right-clicked row
    if (rows.length === 0) {
      rowsPreselected = false;

      const deselectRow = () => {
        clickedRow.removeClass("contextmenu");
      };

      clickedRow.addClass("contextmenu");
      window.addEventListener("click", deselectRow, { once: true });

      rows = dt
        .rows([`#${clickedRow.attr("id")}`])
        .data()
        .toArray();
    }

    this.setState((prev) => ({
      ...prev,
      selectedRowsArray: rows,
      contextMenu: {
        ...prev.contextMenu,
        display: true,
        event: e,
        rowsPreselected: rowsPreselected,
      },
    }));
  };

  render() {
    const {
      dtEditorFields,
      dtDisplayColumns,
      searchValue,
      colSets,
      outputLog,
      outputLogClearedTimestamp,
      modalIsActive,
      labelModalIsActive,
    } = this.state;

    return (
      <Fragment>
        <ContextMenu
          open={this.state.contextMenu.display}
          event={this.state.contextMenu.event}
          selectedRows={this.state.selectedRowsArray}
          rowsPreselected={this.state.contextMenu.rowsPreselected}
          openContextModal={(modalName) =>
            this.setState((prev) => ({
              ...prev,
              contextMenu: { ...prev.contextMenu, modal: modalName },
            }))
          }
          closeContextMenu={() =>
            this.setState((prev) => ({
              contextMenu: {
                ...prev.contextMenu,
                display: false,
                event: null,
              },
            }))
          }
        />
        {this.state.contextMenu.modal === "family" && (
          <FamilyManagement
            open={this.state.contextMenu.modal === "family"}
            close={() => {
              this.dataTablesRef.current.dataTable.ajax.reload();
              this.setState((prev) => ({
                ...prev,
                contextMenu: { ...prev.contextMenu, modal: "" },
              }));
            }}
            loading={this.state.contextMenu.loading}
            selectedRows={this.state.selectedRowsArray}
          />
        )}
        {this.state.contextMenu.modal === "inventoryAdjustment" && (
          <AdjustInventory
            datatable={this.dataTablesRef.current.dataTable}
            open={this.state.contextMenu.modal === "inventoryAdjustment"}
            close={() => {
              this.setState((prev) => ({
                ...prev,
                contextMenu: { ...prev.contextMenu, modal: "" },
              }));
            }}
            userId={this.props.auth.userId}
            productsInitial={this.state.selectedRowsArray}
          />
        )}
        <FilterOptions
          filterOptions={this.state.filterOptions}
          updateFilters={(filters) => {
            this.setState({ filterOptions: filters }, () => {
              this.dataTablesRef.current.dataTable.ajax
                .url(
                  `${baseURL}/datatables/main${createQueryParamsFromOptions(
                    this.state.filterOptions
                  )}`
                )
                .load();
            });
          }}
          isOpen={this.state.displayFilterOptions}
          onClose={() => this.setState({ displayFilterOptions: false })}
        />
        <MessageModal
          open={this.state.isOpenMessageModal}
          msg={this.state.msg}
          setOpen={this.setModalOpen}
          type="copy"
        />
        <ModalSwitch
          closeModal={this.closeFormModal}
          selectedRows={this.state.selectedRowsArray}
          modalName={this.state.modalName}
          appendStatus={this.state.appendStatus}
          appendErrors={this.state.appendErrors}
          columnNames={this.state.appendColumnNames}
          submitAppendForm={this.submitAppendForm}
        />
        {this.state.isLoading && (
          <LoadingModal
            isOpen={this.state.isLoading}
            style={{
              overlay: {
                position: "center",
                top: 0,
                left: 0,
                bottom: 0,
              },
              content: {
                top: 400,
                left: 500,
                right: 500,
                bottom: "auto",
                border: "none",
                background: "transparent",
              },
            }}
          >
            <div className="loadingIcon">
              <LoadingIcon />
            </div>
          </LoadingModal>
        )}

        <PricetoolSidebar
          clearSidebar={this.clearOutputLog}
          openPricetoolLink={this.openPricetoolLink}
          content={
            <PricetoolLog
              content={outputLog}
              clearedTimestamp={outputLogClearedTimestamp}
            />
          }
        >
          <ReactModal
            title=""
            content={
              <Modal.Description>
                {
                  <BarcodeGenerator
                    height={50}
                    fontSize={22}
                    textMargin={1}
                    margin={0}
                    barCodeType={this.barCodeType}
                    rowData={this.rowData}
                  />
                }
              </Modal.Description>
            }
            isActive={modalIsActive}
            setOpen={this.setModalOpen}
          />
          <ReactModal
            title=""
            content={
              <Modal.Description>
                {<ShelvingLabelGenerator rowData={this.rowData} />}
              </Modal.Description>
            }
            isActive={labelModalIsActive}
            setOpen={this.setModalOpen}
          />

          <Segment.Group horizontal>
            <Segment>
              <div className="ui input" style={{ marginRight: "32px" }}>
                <input
                  value={searchValue}
                  onChange={this.onChangeSearch}
                  onKeyDown={this.handleSubmit}
                  autoComplete={"off"}
                  type="text"
                  placeholder="Search ..."
                />
              </div>

              <FloatingLabelButton
                label="More Filters"
                labelValue={this.filterCount()}
                color={this.filtersAreActive() ? "blue" : null}
                onClick={() => this.setState({ displayFilterOptions: true })}
              />

              {this.filtersAreActive() && (
                <ActiveFilters filters={this.state.filterOptions} />
              )}
            </Segment>
            <Segment>
              <ColumnSetForm
                colSets={_.keys(colSets)}
                handleReorder={this.setColumnSet}
              />
            </Segment>
          </Segment.Group>

          <DataTablesEditor
            key={this.state.render}
            ref={this.dataTablesRef}
            ajax={{
              url: `${baseURL}/datatables/main${createQueryParamsFromOptions(
                this.state.filterOptions
              )}`,
              contentType: "application/json",
              type: "POST",
              data: (d) => {
                this.state.dtDisplayColumns.forEach((col) => {
                  const i = d.columns.findIndex(
                    (column) => column.data === col.data
                  );
                  if (col.filterNulls) d.columns[i].filterNulls = true;
                  if (col.excludeFromGeneralSearch === true)
                    d.columns[i].excludeFromGeneralSearch = true;
                });
                d.smartSearch = this.state.searchValue;
                return JSON.stringify(d);
              },
            }}
            editorAjax={{
              url: `${baseURL}/datatables/main`,
              contentType: "application/json",
              type: "POST",
              data: function (d) {
                return JSON.stringify(d);
              },
            }}
            handleContextMenu={this.handleContextMenu}
            columns={dtDisplayColumns}
            options={this.datatableOptions}
            fields={dtEditorFields}
            createButton={true}
            customCreate={this.openCreateForm}
            onDraw={() => this.setState({ isLoading: false })}
            customEdit={this.openEditForm}
            editButton={true}
            removeButton={true}
            colVis={false}
            exportButton={true}
            customButtons={this.customButtons}
            columnSearch={true}
            handleNullFilter={this.handleNullFilter}
            storeColumnIndex={this.storeColumnIndex}
            inlineEditHandler={this.inlineEditHandler}
          />
        </PricetoolSidebar>
      </Fragment>
    );
  }
}
//}

const mapStateToProps = (state) => {
  const { columnSets, auth } = state;
  return { columnSets, auth };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchColumnSets: (id) => dispatch(fetchColumnSets(id)),
    addNotification: (notification) => dispatch(addNotification(notification)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pricetool);
