import React from "react";
import $ from "jquery";
import "datatables.net";
import "datatables.net-se";

import "datatables.net-buttons";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-select";
import "datatables.net-editor";
import "datatables.net-colreorder";

import "datatables.net-buttons-se";
import "datatables.net-select-se";
import "datatables.net-editor-se";
import "datatables.net-colreorder-se";

import "datatables.net-se/css/dataTables.semanticui.min.css";
import "datatables.net-buttons-se/css/buttons.semanticui.min.css";
import "datatables.net-select-se/css/select.semanticui.min.css";
import "datatables.net-editor-se/css/editor.semanticui.min.css";
import "datatables.net-colreorder-se/css/colReorder.semanticui.min.css";

import "./DataTablesEditor.css";
import { getTableColumnWidth } from "../../utilities/domFunctions";
import { getInlineInput, setActiveInlineInputWidth } from "./inlineEditing";
import {
  attachFamiliesInputOnCreate,
  emitCloseEventOnBackgroundClick,
} from "./dataTableEvents";
import { attachCustomDatatableTooltips } from "./Custom/attachCustomTooltips";
import { overrideInlineInput } from "./Custom/inlineEditOverrides";

window.$ = window.jQuery = require("jquery");
require("semantic-ui-css/semantic");

export default class DataTablesEditor extends React.Component {
  constructor(props) {
    super(props);
    this.dataTable = null;
    this.editor = null;
    this.searchDebounce = null;
  }

  componentDidMount() {
    this.$el = $(this.el);

    this.editor = new $.fn.dataTable.Editor({
      ajax: this.props.editorAjax,
      table: this.$el,
      fields: this.props.fields,
    });

    this.dataTable = this.$el.DataTable({
      ajax: this.props.ajax,
      columns: this.props.columns,

      ...this.props.options,
      ...this.initButtons(this.props),
    });

    this.dataTable.on("draw", attachCustomDatatableTooltips);
    this.dataTable.on("draw", () => {
      this.dataTable.columns.adjust();
    });

    if (this.props.onDraw) this.dataTable.on("draw", this.props.onDraw);

    emitCloseEventOnBackgroundClick(this.editor);

    attachFamiliesInputOnCreate(this.editor);

    this.editor.on("opened", (e, mode, action) => {
      this.editor.error(""); // clearing any lingering messages/errors on open
    });

    this.editor.on("submitComplete", (e, res, json, action) => {
      const { message } = res;

      // Adding message as error - if not an error, form will close on success anyways
      // No error specific 'on' event seems to be in our version
      this.editor.error(message);
    });

    $(this.dataTable.table().container()).on(
      "dblclick",
      "tbody td:not(:first-child)",
      (e) => {
        const $td = $(e.target);
        const row = this.dataTable.row($td.closest("tr"));
        if (this.props.inlineEditHandler) {
          this.props.inlineEditHandler(row.data());
        }
        const headerText = $td
          .closest("table")
          .find("th")
          .eq($td.index())
          .text();

        const colWidth = getTableColumnWidth(e.target);
        this.editor.inline(e.target);

        // Handling any custom inline functionality
        overrideInlineInput(headerText, $td, row, this.editor);

        // Getting inline input for further modifying (if needed)
        const inlineInput = getInlineInput();
        if (!inlineInput) return;

        // Modifying input width when open for improved UX
        setActiveInlineInputWidth(colWidth, inlineInput);
      }
    );

    if (this.props.columnSearch) {
      this.createColumnSearch();
    }

    if (this.props.handleContextMenu) {
      $(this.dataTable.table().body()).on(
        "contextmenu",
        "tr",
        this.props.handleContextMenu
      );
    }

    if (this.props.rowExpansion) {
      let _this = this;

      $(this.dataTable.table().body()).on(
        "click",
        "td.details-control",
        function () {
          let tr = $(this).closest("tr");
          let row = _this.dataTable.table().row(tr);

          if (row.child.isShown()) {
            // This row is already open - close it
            row.child.hide();
            tr.removeClass("shown");
          } else {
            // Open this row
            let data = _this.props.rowExpansion(row.data());

            if (data != null) {
              row.child(data).show();
              tr.addClass("shown");
            }
          }
        }
      );
    }
  }

  executeHeaderSearch(event, columnName) {
    this.dataTable
      .column(`${columnName}:name`)
      .search(event.target.value)
      .draw();
  }

  // duplicates header and turns top one into column search fields
  createColumnSearch = () => {
    let shouldDraw = false;

    // set static table height
    $(".dataTables_scrollBody").css("height", "55vh");

    $(this.dataTable.header()[0].firstChild)
      .clone(true)
      .appendTo(this.dataTable.header()[0]);

    const visibleColumns = this.props.columns.filter(
      (col) => col.visible !== false
    );

    $(this.dataTable.header()[0].children[1].children).each((i, elem) => {
      const columnName = visibleColumns[i].data;

      const columnTitle = $(elem).text();
      // removes sorting icon
      $(elem).addClass("without-sort-icon");

      // disables sorting on header search click
      $(elem).off("click");

      // format the header search as desired
      $(elem).removeClass("sorting");
      $(elem).css("background", "white");
      $(elem).css("white-space", "nowrap");

      const div = document.createElement("div");

      const defaultValue = visibleColumns[i]?.search?.value;
      const showSearchInput = columnTitle !== "";

      if (showSearchInput) {
        // Create the input elements
        const input = document.createElement("input");
        input.id = `header_search_${columnName}`;
        input.className = "filter_header";
        input.type = "text";
        input.style.width = "50%";
        input.placeholder = columnTitle;
        input.value = defaultValue ? defaultValue : "";
        input.className = "header_search_input_dt";

        // Add event listeners to the input elements
        input.addEventListener("keyup", (e) => {
          clearTimeout(this.searchDebounce);
          this.searchDebounce = setTimeout(
            () => this.executeHeaderSearch(e, columnName),
            500,
            e,
            columnName
          );
        });
        div.appendChild(input);
      }

      const checkbox = document.createElement("input");
      checkbox.id = `header_checkbox_${columnName}`;
      checkbox.type = "checkbox";
      if (showSearchInput) checkbox.style.marginLeft = "3px";
      checkbox.className = "header_checkbox_input_dt";

      div.appendChild(checkbox);

      checkbox.addEventListener("click", (e) => {
        if (e.target.checked) {
          this.props.handleNullFilter(columnName, true, this.dataTable);
        } else {
          this.props.handleNullFilter(columnName, false, this.dataTable);
        }
      });

      // Append the input elements to the `elem` node
      $(elem).html(div);

      // initial load if data already exists
      if (defaultValue) {
        this.dataTable.column(i).search(defaultValue);
        shouldDraw = true;
      }
    });

    if (shouldDraw) {
      this.dataTable.draw();
    }
  };

  componentWillUnmount() {
    this.dataTable.destroy(false);
  }

  initButtons = (props) => {
    let o = { buttons: [] };
    if (props.createButton) {
      if (props.customCreate) {
        o.buttons.push({
          extend: "create",
          editor: this.editor,
          action: props.customCreate,
        });
      } else {
        o.buttons.push({
          extend: "create",
          editor: this.editor,
        });
      }
    }

    if (props.editButton) {
      if (props.customEdit) {
        o.buttons.push({
          extend: "edit",
          editor: this.editor,
          action: props.customEdit,
        });
      } else {
        o.buttons.push({ extend: "edit", editor: this.editor });
      }
    }
    if (props.removeButton)
      o.buttons.push({ extend: "remove", editor: this.editor });
    if (props.colVis)
      o.buttons.push({
        extend: "colvis",
        text: "<span title='Show/Hide Columns'>Column Visibility</span>",
      });
    if (props.exportButton)
      o.buttons.push({
        extend: "collection",
        text: "Export",
        buttons: [
          {
            extend: "copyHtml5",
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "excelHtml5",
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "csvHtml5",
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "pdfHtml5",
            exportOptions: {
              columns: ":visible",
            },
          },
        ],
      });
    if (props.customButtons)
      return { buttons: [...o.buttons, ...props.customButtons()] };
    if (o.buttons.length > 0) return o;
    return null;
  };

  search = (value) => {
    this.dataTable.search(value).draw();
  };

  render() {
    return (
      <table
        className="ui celled table nowrap"
        style={{ minWidth: "100%" }}
        ref={(el) => (this.el = el)}
      />
    );
  }
}
