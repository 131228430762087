export const supplyCreateFields = [
  "supply.upc",
  "supply.supply_title",
  "supply.supply_image",
  "supply.supply_asin",
  "supply.sort",
  "supply.ebay_itemid",
  "supply.walsku",
  "supply.sup_lin",
  "supply.studio",
];

export const productEditFields = [
  "product.title",
  "product.asin",
  "product.product_image",
  "product.sort",
  "media_types[].id",
  "product.genre",
  "product.osid",
  "product.cogs",
  "product.yom_handling_cost_adjustment",
  "product.part_number",
  "product.wn",
  "product.wwo",
  "product.component_count",
  "product.ship_weight",
  "product.prod_list_type",
  "product.an",
  "product.listory",
  "product.orders",
];

export const supplyEditFields = [
  "supply.upc",
  "supply.supply_title",
  "supply.supply_image",
  "supply.supply_asin",
  "supply.sort",
  "supply.ebay_itemid",
  "supply.walsku",
  "supply.sup_lin",
  "supply.studio",
  "product.shelf",
  "product.prod_list_type",
  "product.rstat",
];
