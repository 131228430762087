import React, { useEffect, useState } from "react";
import { flushSync } from "react-dom";
import { ErrorMessage, Formik } from "formik";
import {
  Modal,
  Header,
  Form,
  Dropdown,
  Button,
  Segment,
} from "semantic-ui-react";
import * as Yup from "yup";
import { sumNumericColumns } from "./sumFunctions";
import dvdlocator from "../../../../../apis/dvdlocator";
import LoadingBox from "../../../../reports/LoadingBox";

const columnSelectionOptions = [
  {
    key: "DTB1",
    text: "DTB1",
    value: "supply.dtb1",
  },
  {
    key: "DTB2",
    text: "DTB2",
    value: "supply.dtb2",
  },
  {
    key: "DTB3",
    text: "DTB3",
    value: "supply.dtb3",
  },
  {
    key: "Inv",
    text: "Inv",
    value: "product.inv",
  },
  {
    key: "ONO",
    text: "ONO",
    value: "product.ono",
  },
  {
    key: "30 Day Page Sales",
    text: "30 Day Page Sales",
    value: "product_listings.30_day_sales",
  },
  {
    key: "Amz Ship Qty",
    text: "Amz Ship Qty",
    value: "product.amz_ship_qty",
  },
  {
    key: "Next Ship Qty",
    text: "Next Ship Qty",
    value: "product.next_amz_ship_qty",
  },
  {
    key: "New Shipped Stock",
    text: "New Shipped Stock",
    value: "product.ship_stock",
  },
  {
    key: "Total Orders",
    text: "Total Orders",
    value: "product.total_orders",
  },
  {
    key: "Alliance Distributor Price",
    text: "Alliance Distributor Price",
    value: "supply.alliance",
  },
  {
    key: "MAVCG",
    text: "MAVCG",
    value: "product.master_avg_cost_of_goods_cents",
  },
  {
    key: "MRCOG-P",
    text: "MRCOG-P",
    value: "product.replacement_cost_cents",
  },
  {
    key: "MRCOG-IS",
    text: "MRCOG-IS",
    value: "product.replacement_cost_dist_cents",
  },
  {
    key: "Margin",
    text: "Margin",
    value: "product.margin",
  },
  {
    key: "ROI",
    text: "ROI",
    value: "product.roi",
  },
  {
    key: "30 Day Profit",
    text: "30 Day Profit",
    value: "product_listings.30_day_profit",
  },
];

// Columns that use cents
const columnsThatUseCents = [
  "product.replacement_cost_cents",
  "product.replacement_cost_dist_cents",
  "product.master_avg_cost_of_goods_cents",
];

const columnDisplayName = (colValue) => {
  const column = columnSelectionOptions.filter(
    (col) => col.value === colValue
  )[0];
  return column.text;
};

const RedErrorText = ({ children }) => (
  <p style={{ color: "red", marginBottom: "3px" }}>{children}</p>
);

export const rowsContainDuplicateMembers = (rows) => {
  const familyAndNumTracker = {};

  for (const row of rows) {
    const families = row.collection?.member_of?.trim();
    const part = row?.collection?.num?.trim();

    // Skip if not assigned a family or part
    if (!families || !part) continue;

    // Checking if its a duplicate component (same part number, same family)
    let isDupe = false;

    families
      .split(" ")
      .filter((fam) => fam) // filtering empty strings
      .forEach((family) => {
        const memRecord = `Family:${family.trim()}Part:${part.trim()}`;

        if (familyAndNumTracker[memRecord]) isDupe = true;
        else familyAndNumTracker[memRecord] = 1;
      });

    // Exit if we've found a duplicate
    if (isDupe) return isDupe;
  }

  return false;
};

const SumColumnForm = ({ closeModal, rows }) => {
  const [displayState, setDisplayState] = useState("select");
  const [sum, setSum] = useState(null);
  const [warning, setWarning] = useState("");
  const [colName, setColName] = useState("");

  const calculateSum = (colFieldValue) => {
    setDisplayState("loading");

    setColName(columnDisplayName(colFieldValue));

    const columnUsesCents = columnsThatUseCents.includes(colFieldValue);

    let sum = sumNumericColumns(rows, colFieldValue);

    // Converting to dollars
    if (columnUsesCents) sum = sum / 100;

    // setting floats to two decimal places
    if (!Number.isInteger(sum)) sum = sum.toFixed(2);

    setDisplayState("submitted");
    setSum(sum);
  };

  const calculateSumAll = (colFieldValue) => {
    setDisplayState("loading");
    dvdlocator
      .post("sum-column", { table_with_col: colFieldValue })
      .then((res) => {
        let sum = parseFloat(res.data.sum);

        const columnUsesCents = columnsThatUseCents.includes(colFieldValue);

        // Converting to dollars
        if (!isNaN(sum) && columnUsesCents) sum = sum / 100;
        // setting floats to two decimal places
        if (!isNaN(sum)) sum = sum.toFixed(2);

        setColName(columnDisplayName(colFieldValue));
        setDisplayState("submitted");
        setSum(sum);
      })
      .catch((err) => alert(err));
  };

  useEffect(() => {
    if (rowsContainDuplicateMembers(rows))
      setWarning(
        "Warning: Selected rows contain duplicate components. Summing your selection may include redundant/duplicate values."
      );
  }, [rows]);

  if (displayState === "loading") {
    return (
      <Modal onClose={closeModal}>
        <LoadingBox />
      </Modal>
    );
  }

  return (
    <Modal
      defaultOpen
      style={{ minHeight: "300px", minWidth: "600px" }}
      onClose={closeModal}
    >
      {displayState === "select" && (
        <ColumnSelectionForm
          setDisplayState={setDisplayState}
          calculateSum={calculateSum}
          calculateSumAll={calculateSumAll}
          closeModal={closeModal}
          selectedDisabled={rows.length < 1}
          warning={warning}
        />
      )}
      {displayState === "submitted" && (
        <SumDisplay
          sum={sum}
          closeModal={closeModal}
          columnName={colName}
          warning={warning}
        />
      )}
    </Modal>
  );
};

const SumDisplay = ({ closeModal, sum, columnName, warning }) => {
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(sum);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 1000);
  };

  return (
    <Segment
      basic
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {/* Header */}
      <div style={{ padding: "30px 0" }}>
        <Header size="large" textAlign="center">
          Sum for selected column: {columnName}
        </Header>
        <span style={{ color: "red" }}>{warning}</span>
      </div>

      <h3>Sum: {sum}</h3>

      {/* Button Container */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: "12px",
          width: "400px",
        }}
      >
        <Button onClick={closeModal}>Close</Button>
        <Button onClick={handleCopy} color={copySuccess ? "green" : ""}>
          {copySuccess ? "Copied" : "Copy"}
        </Button>
      </div>
    </Segment>
  );
};

const ColumnSelectionForm = ({
  selectedDisabled,
  calculateSum,
  calculateSumAll,
  closeModal,
  warning,
}) => {
  const [submitType, setSubmitType] = useState("selected");

  const validationSchema = Yup.object({
    columnName: Yup.string().required(),
  });
  return (
    <Formik
      initialValues={{ columnName: "" }}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        if (submitType === "selected") calculateSum(values.columnName);
        if (submitType === "all") calculateSumAll(values.columnName);
      }}
    >
      {(formikProps) => (
        <Form onSubmit={formikProps.handleSubmit}>
          <div
            style={{
              display: "flex",
              flexFlow: "column nowrap",
              alignItems: "center",
              marginTop: "48px",
            }}
          >
            {/* Header */}
            <div style={{ padding: "30px 0" }}>
              <Header size="large" textAlign="center">
                Select a column to sum
              </Header>
              <span style={{ color: "red" }}>{warning}</span>
            </div>

            <ErrorMessage name="columnName" component={RedErrorText} />

            <Dropdown
              name="columnName"
              style={{ maxWidth: "400px" }}
              placeholder="Select Column"
              value={formikProps.values["columnName"]}
              onChange={(e, data) => {
                formikProps.setFieldTouched("columnName", true, false);
                formikProps.setFieldValue("columnName", data.value);
              }}
              fluid
              search
              selection
              options={columnSelectionOptions}
            />
          </div>
          {/* Button Container */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "48px",
            }}
          >
            <Button secondary onClick={closeModal} type="button">
              Cancel
            </Button>
            <Button
              type="button"
              color="blue"
              disabled={formikProps.isSubmitting || selectedDisabled}
              onClick={() => {
                flushSync(() => {
                  setSubmitType("selected");
                });
                formikProps.handleSubmit();
              }}
            >
              Sum Selected
            </Button>
            <Button
              type="button"
              color="blue"
              disabled={formikProps.isSubmitting}
              onClick={() => {
                flushSync(() => {
                  setSubmitType("all");
                });
                formikProps.handleSubmit();
              }}
            >
              Sum All
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SumColumnForm;
