import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Grid,
  Dropdown,
  Input,
  Table,
  Header,
  Icon,
  Loader,
  Segment,
  Dimmer,
} from "semantic-ui-react";
import dvdlocator from "../../../../../apis/dvdlocator";
import * as Yup from "yup";
import { Formik, ErrorMessage } from "formik";
import _ from "lodash";
import { flushSync } from "react-dom";

const orderDropDownOptions = [
  {
    key: "Repair",
    text: "Repair",
    value: "Repair",
  },
  {
    key: "Return",
    text: "Return",
    value: "Return",
  },
];

const RedErrorText = ({ children }) => (
  <p style={{ color: "red", marginBottom: "3px" }}>{children}</p>
);

/**
 * List item component that is mapped inside of the CreateWorkOrder component.
 * This component handles the individual row state control and display.
 */
export const WorkOrderInputRow = ({
  row,
  deleteRow,
  formikProps,
  loadingQuantities,
}) => {
  const rowIsValid = row.dmg_inv_display !== "0" && row.dmg_inv_display;
  // Read values from Formik accounting for initial null during mounting
  const typeValue =
    formikProps.values[row.yom_sku] && formikProps.values[row.yom_sku][`type`];
  const quantityValue =
    formikProps.values[row.yom_sku] &&
    formikProps.values[row.yom_sku][`quantity`];

  const dmgInvTitle = `Damage inventory quantities per reason code. In order of 1-2-3. 
    \n1 - Can only be repaired.
    \n2 - Can be repaired or returned.
    \n3 - Can only be returned.`;

  const typeTitle = `Define the type of order. Will be processed in FIFO order, and will prioritize 1's and 3's over 2's in all cases.
    \nRepair - Can process 1's, 2's.
    \nReturn - Can process 3's, 2's.`;

  // Nested format for Formik state access
  const orderFieldName = `${row.yom_sku}.type`;
  const quantityFieldName = `${row.yom_sku}.quantity`;

  return (
    <Grid.Row>
      {/* Product Information */}
      <Grid.Column width={8}>
        <Table celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={4}>UPC</Table.HeaderCell>
              <Table.HeaderCell width={8}>Title</Table.HeaderCell>
              <Table.HeaderCell
                style={{ cursor: "help" }}
                title={dmgInvTitle}
                width={4}
              >
                Damage Inventory
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ cursor: "help" }}
                title="How many damage code 1 and 2 rows are available to mark."
                width={4}
              >
                Available Repair
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ cursor: "help" }}
                title="How many damage code 2 and 3 rows are available to mark."
                width={4}
              >
                Available Return
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell width={4}>{row.upc}</Table.Cell>
              <Table.Cell width={8}>
                <span dangerouslySetInnerHTML={{ __html: row.title }} />
              </Table.Cell>
              <Table.Cell width={4}>{row.dmg_inv_display}</Table.Cell>
              <Table.Cell width={4} data-testid="row-inventory-cell">
                {loadingQuantities ? (
                  <Segment padded data-testid="row-loader-segment">
                    <Dimmer active inverted>
                      <Loader active size="small" />
                    </Dimmer>
                  </Segment>
                ) : (
                  row.available_repair
                )}
              </Table.Cell>
              <Table.Cell width={4} data-testid="row-inventory-cell">
                {loadingQuantities ? (
                  <Segment padded data-testid="row-loader-segment">
                    <Dimmer active inverted>
                      <Loader active size="small" />
                    </Dimmer>
                  </Segment>
                ) : (
                  row.available_return
                )}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Grid.Column>
      {/* End Product Information */}

      {/* User Inputs */}
      <Grid.Column width={2}>
        <ErrorMessage name={orderFieldName} component={RedErrorText} />
        <Dropdown
          name={orderFieldName}
          id={orderFieldName}
          style={{ cursor: "help" }}
          title={typeTitle}
          placeholder="Order Type"
          onChange={(e, data) => {
            formikProps.setFieldTouched(orderFieldName, true, false);
            formikProps.setFieldValue(orderFieldName, data.value);
          }}
          value={typeValue}
          fluid
          selection
          disabled={formikProps.isSubmitting || !rowIsValid}
          options={orderDropDownOptions}
        />
      </Grid.Column>
      <Grid.Column width={2}>
        <ErrorMessage name={quantityFieldName} component={RedErrorText} />
        <Input
          id={quantityFieldName}
          name={quantityFieldName}
          placeholder="Enter quantity"
          onChange={(e) => {
            formikProps.setFieldTouched(quantityFieldName, true, false);
            formikProps.setFieldValue(
              quantityFieldName,
              parseInt(e.target.value, 10)
            );
          }}
          value={quantityValue}
          type="number"
          fluid
          disabled={formikProps.isSubmitting || !rowIsValid || !typeValue}
        />
      </Grid.Column>

      <Grid.Column width={2} textAlign="center">
        <Button
          compact
          type="button"
          color="red"
          icon="trash"
          title="Remove row from selection."
          disabled={formikProps.isSubmitting}
          onClick={() => deleteRow(row.yom_sku, formikProps.values)}
        />
      </Grid.Column>
      {/* End User Inputs */}

      {/* Status Column */}
      <Grid.Column width={2} textAlign="center">
        {!rowIsValid && (
          <span style={{ color: "red" }}>No inventory available.</span>
        )}
        {row.error && (
          <span style={{ color: "red" }} data-testid="row-error-span">
            {row.error}
          </span>
        )}
        {row.success && (
          <Icon
            style={{ color: "green" }}
            name="check circle"
            size="big"
            data-testid="row-success-icon"
          />
        )}
      </Grid.Column>
      {/* End Status Column */}
    </Grid.Row>
  );
};

const ResponseStatus = ({ rows, responseData }) => {
  switch (responseData.status) {
    case "Error":
      return (
        <p style={{ color: "red" }}>
          {responseData.message
            ? responseData.message
            : "One or more rows failed to process."}
        </p>
      );

    case "Success":
      return (
        <p
          style={{ color: "green" }}
        >{`${rows.length} / ${rows.length} rows succeeded.`}</p>
      );

    default:
      return null;
  }
};

/**
 * Component made of a modal and form that handles creating work orders.
 * @param {array} rows An array of rows.
 * @returns
 */
const CreateWorkOrder = ({ rows, closeModal }) => {
  const [formattedRows, setFormattedRows] = useState([]);
  const [formData, setFormData] = useState({});
  const [loadingQuantities, setLoadingQuantities] = useState(false);
  const [responseData, setResponseStatus] = useState({
    status: "",
    message: "",
  });

  /**
   * Determines if a given quantity and type are
   * valid against a given row's available quantities.
   * @param {object} row
   * @param {number} quantity
   * @param {string} type
   * @returns {boolean}
   */
  const quantityValid = (row, type, quantity) => {
    if (!row.available_repair && !row.available_return) return false;

    if (type === "Repair") {
      return quantity <= row.available_repair;
    }
    if (type === "Return") {
      return quantity <= row.available_return;
    }
    return false;
  };

  /**
   * Parses Yup meta data and returns the yomSku and type
   * @param {object} meta
   * @returns {array} [yomSku, type]
   */
  const parseYupMetadata = (meta) => {
    const yomSku = parseInt(meta.path.split(".")[0], 10);
    if (isNaN(yomSku)) return [null, null];

    const type = meta.from[meta.from.length - 1].value[yomSku][`type`];

    return [yomSku, type];
  };

  /**
   * Gets values required for validating dmgInvCheck test method.
   * @param {object} meta
   * @returns {object} { row, yomSku, type }
   */
  const getDmgInvCheckArgs = (meta) => {
    const [yomSku, type] = parseYupMetadata(meta);
    if (!yomSku) return { row: null, yomSku: null, type: null };

    const row = formattedRows.filter((row) => row.yom_sku === yomSku)[0];
    return { row, yomSku, type };
  };

  /**
   * Creates a schema object for a given sku.
   * @param {number} yomSku
   * @returns {object} Field Schema
   */
  const fieldSchemaForSku = (yomSku) => {
    return {
      [yomSku]: Yup.object({
        quantity: Yup.number()
          .moreThan(0, "Please enter a quantity greater than 0.")
          .test(
            "dmgInvCheck",
            "Quantity exceeds available inventory.",
            (value, meta) => {
              const { row, yomSku, type } = getDmgInvCheckArgs(meta);
              if (!yomSku) return null;
              return quantityValid(row, type, value);
            }
          )
          .required("Please enter a valid quantity"),
        type: Yup.string().required("Please enter a valid type"),
      }),
    };
  };
  /**
   * Yup validation schema - gets filled with all the fields
   * that are generated inside formData.
   */
  const validationSchema = Yup.object({
    ...Object.values(formData).reduce((fieldAccumulator, data) => {
      const yomSku = data.yom_sku;

      fieldAccumulator = {
        ...fieldAccumulator,
        ...fieldSchemaForSku(yomSku),
      };
      return fieldAccumulator;
    }, {}),
  });

  /**
   * Gets only fields required for work order display.
   * @param {object} row
   * @returns {object}
   */
  const formatRequiredFields = (row) => {
    const yom_sku = row.product.yom_sku;
    const upc = row.supply.upc;
    const title = row.supply.supply_title;
    const dmg_inv_display = row.supply.dmg_inv_display;
    return {
      yom_sku,
      upc,
      title,
      dmg_inv_display,
      return_quantity: "loading",
      repair_quantity: "loading",
    };
  };

  /**
   * Creates a hashtable of form data field objects.
   * These objects will include unique field names
   * based on the yom_sku to be validated by Yup.
   *
   * The form data field objects are accessible
   * via the yom_sku.
   * @param {object} initial An initial object to start with.
   * @param {array} rows
   * @returns {object}
   */
  const generateFormDataFieldHash = (initial = {}, rows) => {
    const formDataItems = initial;
    rows.forEach((row) => {
      // don't overwrite existing values
      if (formDataItems[row.yom_sku]) return;

      const formDataObject = {
        yom_sku: row.yom_sku,
        quantity: 0,
        type: "",
      };

      formDataItems[row.yom_sku] = formDataObject;
    });

    return formDataItems;
  };

  /**
   * Gets the available repair/return quantities for a selection
   * of rows and attaches them to the rows in formattedRows state.
   * @param {array} rows
   */
  const setRowQuantities = (rows) => {
    setLoadingQuantities(true);
    const upcs = rows.map((row) => row.upc);
    dvdlocator
      .post("/work-orders/available", { upcs })
      .then((res) => {
        const modifiedArray = res.data.map((row) => {
          const upc = row.upc;

          const rowToUpdate = rows.filter((row) => row.upc === upc)[0];

          return {
            ...rowToUpdate,
            available_repair: row.available_repair_quantity,
            available_return: row.available_return_quantity,
          };
        });

        setFormattedRows(modifiedArray);
        setLoadingQuantities(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingQuantities(false);
      });
  };

  /**
   * Formats rows and creates the initial formData structure
   * that gets consumed by Yup and Formik.
   */
  useEffect(() => {
    const workOrderFormattedRows = rows
      .filter((row) => row.product.yom_sku)
      .map((row) => formatRequiredFields(row));

    setFormData((prevData) =>
      generateFormDataFieldHash(prevData, workOrderFormattedRows)
    );

    setFormattedRows(workOrderFormattedRows);
    setRowQuantities(workOrderFormattedRows);
  }, [rows]);

  /**
   * Deletes a row from the selection.
   * @param {number} yomSku
   * @param {object} prevValues Previous formik internal state
   */
  const deleteRow = (yomSku, prevValues) => {
    // Cloning to persist inputs user has interacted with
    const prevValuesClone = _.cloneDeep(prevValues);
    delete prevValuesClone[yomSku];

    // Flushes state update **syncronously**
    flushSync(() => {
      setFormData(prevValuesClone);
    });

    setFormattedRows((prevRows) =>
      prevRows.filter((row) => row.yom_sku !== yomSku)
    );
  };

  const generateWorkOrders = (values) => {
    const workOrders = [];

    Object.entries(values).forEach((entry) => {
      const [, val] = entry;
      workOrders.push(val);
    });

    return workOrders;
  };

  const attachStatusToRow = (row, responseObject) => {
    const error = responseObject.error;

    if (error) {
      row.error = error;
    } else row.success = true;

    return row;
  };

  const getResObjectForRow = (yomSku, responseObjects) => {
    const resObjectForRow = responseObjects.filter(
      (obj) => obj.yom_sku === yomSku
    );
    if (!resObjectForRow.length > 0) {
      return null;
    }
    return resObjectForRow[0];
  };

  /**
   *
   * @param {object} row
   * @param {array} responseObjects
   */
  const processRowAfterSubmit = (row, responseObjects) => {
    const resObject = getResObjectForRow(row.yom_sku, responseObjects);
    if (!resObject) return row;
    // updating
    const shallowCloneRow = { ...row };
    return attachStatusToRow(shallowCloneRow, resObject);
  };

  /**
   * Sends a request to the backend containing desired work orders.
   */
  const sendWorkOrders = (workOrders) => {
    setResponseStatus({ status: "", message: "" });
    return dvdlocator
      .post("/work-orders/mark", { work_orders: workOrders })
      .then((res) => {
        setResponseStatus({ status: "Success", message: "" });
        const processedRows = formattedRows.map((row) =>
          processRowAfterSubmit(row, res.data)
        );
        setRowQuantities(processedRows);
      })
      .catch((err) => {
        setResponseStatus({ status: "Error", message: "" });

        const data = err.response?.data;
        if (data && data.length > 0) {
          const processedRows = formattedRows.map((row) =>
            processRowAfterSubmit(row, data)
          );
          setRowQuantities(processedRows);
        }

        if (!data) {
          setResponseStatus({
            status: "Error",
            message: "There was a connection problem.",
          });
        }
      });
  };

  return (
    <Modal
      data-testid="create-work-order-form"
      defaultOpen
      style={{ minHeight: "350px", minWidth: "1400px" }}
      onClose={closeModal}
    >
      {/* Header */}
      <div style={{ padding: "30px 0" }}>
        <Header size="large" textAlign="center">
          Create Work Order(s)
        </Header>
      </div>
      {/* End Header */}

      <Formik
        initialValues={formData}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={async (values, actions) => {
          const workOrders = generateWorkOrders(values);
          await sendWorkOrders(workOrders);
        }}
      >
        {(formikProps) => (
          <form onSubmit={formikProps.handleSubmit}>
            {/* Rows and Inputs Mapping */}
            <div
              style={{
                padding: "30px 0",
                maxHeight: "70vh",
                overflowY: "scroll",
              }}
            >
              <Grid padded verticalAlign="middle" centered>
                {formattedRows.map((row) => (
                  <WorkOrderInputRow
                    row={row}
                    key={row.yom_sku}
                    deleteRow={deleteRow}
                    formikProps={formikProps}
                    loadingQuantities={loadingQuantities}
                  />
                ))}
              </Grid>
            </div>
            {/* End Rows and Inputs Mapping */}

            {/* Button Inputs */}
            <Grid columns={2} padded verticalAlign="bottom">
              <Grid.Row>
                <Grid.Column floated="left" textAlign="center">
                  <Button secondary onClick={closeModal} type="button">
                    Cancel
                  </Button>
                </Grid.Column>

                <Grid.Column floated="right" textAlign="center">
                  <ResponseStatus
                    rows={formattedRows}
                    responseData={responseData}
                  />
                  <Button
                    type="submit"
                    color={responseData.status === "Error" ? "red" : "blue"}
                    disabled={formikProps.isSubmitting}
                  >
                    Submit
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {/* End Button Inputs*/}
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default CreateWorkOrder;
